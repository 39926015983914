import { getService } from '@/helpers/feathers'
import { BadRequest } from '@feathersjs/errors'
import { Paginated as FeatherPagination } from '@feathersjs/feathers'
import LazyContentLoader from '@/components/LazyContentLoader/LazyContentLoader.vue'
import { CustomPaginated } from '@/interfaces/CustomPaginated.interface'
import { BaseDataset } from '@/interfaces/ContentLoader/base-dataset.interface'
import { Request } from '@/interfaces/ContentLoader/request.interface'

/**
 * Dieser Typ listet alle unterstützten Pagination-Objekte auf.
 */
export type Paginated =
  | CustomPaginated<BaseDataset>
  | FeatherPagination<BaseDataset>

/**
 * Type für die Callback-Funktionen zu der Abfrage von den Datensätzen einer
 * Datenquelle. Diese wird von der Komponente [[LazyContentLoader]] verwendet.
 */
export type typeSourceCallback = (
  request: Readonly<Request>,
  list: LazyContentLoader
) => Promise<Paginated>

/**
 * Der Standard Callback von der [[LazyContentLoader]], wenn dessen Property
 * [[LazyContentLoader.source]] mit einem String, also einem Service-Pfad,
 * zugewiessen bekommt.
 *
 * @param request - Das komplette Request-Objekt der [[LazyContentLoader]]
 * @param list - Die Instance von der [[LazyContentLoader]], zu der der Aufruf
 * gehört.
 * @returns Die Pagination für die abgefragten Datensätze.
 */
export const serviceSourceCallback: typeSourceCallback = async (
  request,
  list
): Promise<Paginated> => {
  if (typeof list.$props.source !== 'string') {
    throw new TypeError("property `source` isn't from type `string`")
  }

  const servicePath = list.$props.source.trim()
  const result: Paginated = await (await getService<Paginated>(servicePath))
    .find({ query: request })
    .then((x): Paginated => {
      if (
        !x ||
        Array.isArray(x) ||
        (!('limit' in x && 'skip' in x && 'total' in x) &&
          !('limit' in x && 'offset' in x && 'total' in x))
      ) {
        throw new BadRequest(
          "Only services that support the 'Pagination' response type are supported."
        )
      }

      if (!Array.isArray(x.data)) {
        x.data = []
      }
      return x as Paginated
    })
  return result
}
