import { Column } from '@/interfaces/ContentLoader/column.interface'
import { Filter } from '@/interfaces/Filter.interface'
import { Conversion } from '@/constants/conversion.enum'
import { DatabaseComparator } from '@/constants/database-comparator.enum'
import { formatToIsoDate } from '@/helpers/value-format'

type Dictionary<T> = { [key: string]: T }

/**
 * Interface mit den gefundenen Einstellungen aus der URL-Query, welches für die
 * Filter und aktive Suchspalten verwendet werden sollen.
 */
export interface Presetting {
  /**
   * Aktivierte Suchspalten, die von der Suche berücksichtigt werden sollen.
   */
  searchColumns: string[]

  /**
   * Alle gesetzten Filter aus der URL-Query.
   */
  filters: Filter[]
}

/**
 * Liest aus der Query der Route die übergebene Werte für die Einstellungen aus,
 * diese werden als [[Presetting]]-Interface zurückgegeben. [[Column.property]]
 * wird als Schlüssel für das Query-Dictionary verwendet.
 *
 * Es werden bei dem Spaltentyp Datum nur Angaben im Format ISO 8601 und der
 * Zeitzone UTC unterschützt, z. B.: `2020-12-30 oder` `2020-12-30T12:25:25Z`.
 * Damit nur das Enddatum gesetzt wird, muss an den Schlüssel `.from` angehängt
 * werden, für das Enddatum ist es `.to`.
 *
 * @param query - Query der Route, Array als Wert wird nicht Unterschützt
 * @param columns - Spalten, dessen Filter befüllt werden sollen
 * @param prefix - Prefix von den Query-Schlüsseln
 * @returns - [[Presetting]] mit den gesetzen Werten aus der Query
 */
export function parsePresettingFromQuery(
  query: Dictionary<string | (string | null)[]>,
  columns: Column[],
  prefix = ''
): Presetting {
  let entriesCount = Object.keys(query).length
  const resultFilters: Filter[] = []
  const resultSearchColumns: string[] = []

  if (entriesCount !== 0) {
    for (const column of columns) {
      // Prüfen ob noch Filter übrig sind, wenn nicht, abbrechen
      if (entriesCount === 0) {
        break
      }

      // Ist die Spalte nicht durchsuchbar, gibt es auch keine Filter
      if (column.searchable !== true) {
        continue
      }

      const property = `${prefix}${column.property}`

      if (
        (!column.filterAs && column.formatAs === 'date') ||
        column.filterAs === 'range'
      ) {
        let bothDate: string | undefined
        let fromDate: string | undefined
        let toDate: string | undefined

        // Standard
        if (query.hasOwnProperty(property)) {
          const value = query[property]
          --entriesCount

          if (typeof value === 'string') {
            bothDate = formatToIsoDate(value)
          }
        }

        // nur für Startdatum
        if (query.hasOwnProperty(`${property}.from`)) {
          const value = query[`${property}.from`]
          --entriesCount

          fromDate =
            typeof value === 'string' ? formatToIsoDate(value) : bothDate
        } else {
          fromDate = bothDate
        }

        // nur für Enddatum
        if (query.hasOwnProperty(`${property}.to`)) {
          const value = query[`${property}.to`]
          --entriesCount

          toDate = typeof value === 'string' ? formatToIsoDate(value) : bothDate
        } else {
          toDate = bothDate
        }

        if (fromDate) {
          resultFilters.push({
            column: column.property,
            comparator: DatabaseComparator.BiggerOrEqual,
            values: [fromDate.split('T')[0] + 'T00:00:00Z'],
            conversion: Conversion.Date
          })
        }

        if (toDate) {
          resultFilters.push({
            column: column.property,
            comparator: DatabaseComparator.SmallerOrEqual,
            values: [toDate.split('T')[0] + 'T23:59:59Z'],
            conversion: Conversion.Date
          })
        }
        continue
      }

      if (column.filterAs === 'select') {
        if (query.hasOwnProperty(property)) {
          const value = query[property]
          --entriesCount

          if (typeof value === 'string') {
            if (value !== '') {
              resultFilters.push({
                column: column.property,
                comparator: DatabaseComparator.Equal,
                values: [value]
              })
            }
          } else {
            resultFilters.push({
              column: column.property,
              comparator: DatabaseComparator.In,
              values: value
                .map((x: unknown): string =>
                  typeof x === 'string' ? x.trim() : ''
                )
                .filter((x: unknown): boolean => x !== '') as string[]
            })
          }
        }
        continue
      }

      if (query.hasOwnProperty(property)) {
        const value = query[property]
        --entriesCount

        if (value !== '0' && value !== 'false') {
          if (
            (!column.filterAs && column.formatAs === 'icon') ||
            column.filterAs === 'switch'
          ) {
            resultFilters.push({
              column: column.property,
              comparator: DatabaseComparator.Equal,
              values: [property.endsWith('-false') ? '0' : '1'],
              conversion: Conversion.Boolean
            })
          } else {
            resultSearchColumns.push(column.property)
          }
        }
      }
    }
  }

  return {
    filters: resultFilters,
    searchColumns: resultSearchColumns
  }
}
