import { formatListValue } from '@/helpers/value-format'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import { BaseDataset } from '@/interfaces/ContentLoader/base-dataset.interface'
import { i18n } from '@/plugins/i18n'

/**
 * Formatiert den Tabellen- bzw. Listenwert entsprechend der eingestellten
 * Formatierungsregeln.
 *
 * @param index - Index in der gefilterten Liste.
 * @param overrideFormatAs - Ermöglicht das Überschreiben der `formatAs`
 * Angabe von der Spalte
 * @param entryList - Einträge der Liste
 * @returns String für Tabelle oder Liste.
 */
export function getEntry(
  index: number,
  { property, formatAs, formatted, iconTrue, iconFalse, iconFor }: Column,
  entryList: BaseDataset[],
  overrideFormatAs?: 'string' | 'date' | 'check' | 'avatar' | 'icon'
): string {
  const entry = entryList[index] || {}

  return formatListValue(
    i18n,
    entry,
    entry[property],
    overrideFormatAs || formatAs,
    formatted,
    iconTrue,
    iconFalse,
    iconFor
  )
}
