var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-simple-table',{class:[
    'content-table__container',
    _vm.selectEnabled ? ' content-table__container--selected' : ''
  ],attrs:{"dense":_vm.compactEnabled,"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(_vm.selectEnabled)?_c('th',{staticClass:"text-left lazy-loading-list__small-column"}):_vm._e(),_vm._l((_vm.tableColumns),function(item,index){return _c('th',{key:item.property,staticClass:"text-left",style:(_vm.getColumnStyle(index)),on:{"click":function($event){return _vm.onToggleOrder(index)}}},[(_vm.tableColumns[index].property === _vm.orderBy)?[_c('i',[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.orderDirection === _vm.ORDER_DIR_ASCENDING ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")])]:_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))])],2)})],2)]),_c('tbody',_vm._l((_vm.entries),function(entry,index){return _c('tr',{key:index,class:entry && entry._isFooter
            ? 'content-table__row--footer'
            : 'content-table__row--entry',on:{"click":event => _vm.onEntryClick(index, event)}},[(entry && !entry._isFooter)?[(_vm.selectEnabled)?_c('td',{staticClass:"content-table__cell-select",on:{"click":function($event){$event.stopPropagation();return _vm.toggleSelected(entry.id)}}},[_c('v-simple-checkbox',{attrs:{"value":_vm.isSelected(entry.id),"ripple":false,"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSelected(entry.id)}}})],1):_vm._e(),_vm._l((_vm.tableColumns),function(column){return _c('td',{key:column.property},[_c('div',{staticClass:"content-table__cell"},[(column.formatAs === 'avatar')?_c('v-avatar',{staticClass:"my-0",attrs:{"size":_vm.compactEnabled ? 16 : 32}},[_c('v-img',{attrs:{"src":_vm.$data.getEntry(index, column, _vm.entries)}})],1):(column.formatAs === 'icon')?_c('v-icon',{attrs:{"color":column.iconColor
                    ? column.iconColor(entry[column.property], entry)
                    : ''}},[_vm._v(" "+_vm._s(_vm.$data.getEntry(index, column, _vm.entries))+" ")]):[_vm._v(" "+_vm._s(_vm.$data.getEntry(index, column, _vm.entries))+" ")]],2)])})]:(entry && entry._isFooter)?[_c('td',{attrs:{"colspan":_vm.tableColumns.length + (_vm.selectEnabled ? 1 : 0)}},[_c('strong',{domProps:{"textContent":_vm._s(entry.text)}})])]:[(_vm.selectEnabled)?_c('td',{staticClass:"content-table__cell-select"},[_c('v-simple-checkbox',{attrs:{"disabled":"","ripple":false}})],1):_vm._e(),_vm._l((_vm.tableColumns),function(column,index){return _c('td',{key:column.property},[_c('div',{staticClass:"content-table__cell"},[((_vm.getColumnWidth(index) || 100) > 80)?_c('v-skeleton-loader',{attrs:{"height":_vm.itemHeight,"width":(_vm.getColumnWidth(index) || 150) - 50,"type":"table-cell"}}):_vm._e()],1)])})]],2)}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }