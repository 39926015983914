import VueI18n, { IVueI18n } from 'vue-i18n'
import moment from 'moment'
import { BaseDataset } from '@/interfaces/ContentLoader/base-dataset.interface'
import { TypeIconFor } from '@/interfaces/ContentLoader/column.interface'

/**
 * Wandelt gültige Datumwerte zu einem ISO-String (YYYY-MM-DDThh:mm:ssZ) um, als
 * Zeitzone wird 0 UTC verwendet. Wird das Datum als String angegeben, wird
 * dieser durch den Date-Konstruktor geparst.
 *
 * @param value - Gültiges Datum, als Date-Objekt oder String.
 * @returns - ISO-String vom Datum, Zeitzone ist 0 UTC (YYYY-MM-DDThh:mm:ssZ),
 * `undefined` bei ungültigen Werte.
 */
export function formatToIsoDate(value?: string | Date): string | undefined {
  if (value) {
    const date = typeof value === 'string' ? new Date(value) : value
    return isNaN(date.getTime()) ? undefined : date.toISOString()
  }
}

/**
 * Formatiert das angegebene Datum entsprechend der Formatierungsangabe.
 *
 * @param lang - Instance von [[VueI18n]]
 * @param date - JS-Datum (als Objeckt), ISO-String oder Timestamp
 * @param format - Ermöglicht das Abändern des Ausgabeformates
 * @returns Formatierter String.
 */
export function formatDate(
  lang: VueI18n & IVueI18n,
  date: Date | string | number,
  format?: string | null
): string {
  const momentDate = moment(date)

  return !format || format === 'short' || format === 'long'
    ? typeof format !== 'string'
      ? lang.d(momentDate.toDate())
      : lang.d(momentDate.toDate(), format)
    : momentDate.locale(lang.locale).format(format)
}

/**
 * Formatiert den angegebenen Wert anhand der angebenenen Einstellungen. Es
 * wird immer ein String zurückgegeben.
 *
 * @param lang - Instance von [[VueI18n]].
 * @param entry - Eintrag mit allen Eigenschaften.
 * @param value - Wert der Formatiert werden soll.
 * @param targetFormat - Zielformat, Erlaubt: 'string', 'date', 'check',
 * 'avatar' und  'icon'.
 * @param formatted - Formatierungsinformation, sehe [[Column.formatted]].
 * @param iconTrue - Icon, bei dem Wert `true`, sehe [[Column.iconTrue]].
 * @param iconFalse - Icon, bei dem Wert `false`, sehe [[Column.iconFalse]].
 * @param iconFor - Icon, nach dem Wert, sehe [[Column.iconColor]].
 * @returns Der Formatiertewert.
 */
export function formatListValue(
  lang: VueI18n & IVueI18n,
  entry: BaseDataset,
  value: Date | string | number | boolean | undefined,
  targetFormat: 'string' | 'date' | 'check' | 'avatar' | 'icon',
  formatted?: string | null,
  iconTrue?: string | null,
  iconFalse?: string | null,
  iconFor?: TypeIconFor | null
): string {
  const start = '{'
  const end = '}'
  let finalString = ''

  if (targetFormat === 'date') {
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      (typeof value === 'object' && value instanceof Date)
    ) {
      finalString = formatDate(lang, value, formatted)
    }
  } else if (targetFormat === 'icon') {
    if (typeof value === 'boolean' || typeof iconFor !== 'function') {
      finalString = !!value ? iconTrue || 'mdi-check' : iconFalse || 'mdi-close'
    } else {
      finalString = iconFor(value, entry)
    }
  } else if (targetFormat === 'check') {
    finalString = !!value ? '1' : '0'
  } else if (targetFormat === 'avatar' || !formatted) {
    finalString = (value || '').toString()
  } else {
    let decorationStart = 0
    let decorationEnd
    const items = []

    for (let i = 0; i <= formatted.length; i++) {
      if (formatted[i] === start || i === formatted.length) {
        if (formatted[i] === start) {
        }
        decorationEnd = i
        items.push(formatted.slice(decorationStart, decorationEnd))
      }
      if (formatted[i] === end && typeof decorationEnd !== 'undefined') {
        decorationStart = i + 1
        const itemKeySetting = formatted.slice(
          decorationEnd + 1,
          decorationStart - 1
        )

        const setting = itemKeySetting.split('||', 3)
        const itemKey: string = (setting[0] || '').trim()
        const successText: string = setting[1] || '??'
        const failedText: string = setting[2] || ''

        const value = entry[itemKey]
        const isDate = new RegExp(
          '^[0-9]{1,}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\\.[0-9]{3}Z$',
          'i'
        )

        if (
          (typeof value === 'object' && value instanceof Date) ||
          (typeof value === 'string' && isDate.test(value))
        ) {
          items.push(successText.replace('??', formatDate(lang, value)).trim())
        } else if (typeof value === 'boolean') {
          items.push(successText.replace('??', value ? '✓' : '✗').trim())
        } else if (
          (typeof value === 'string' && value) ||
          typeof value === 'number'
        ) {
          items.push(successText.replace('??', value.toString()).trim())
        } else if (failedText) {
          items.push(failedText)
        }
      }
    }

    finalString = items.join('')
  }

  return finalString
}
