/**
 * Sortierreihenfolge.
 */
export enum OrderDirection {
  /**
   * Aufsteigend.
   */
  Ascending = 'asc',

  /**
   * Absteigend.
   */
  Descending = 'desc'
}
