import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { BaseDataset } from '@/interfaces/ContentLoader/base-dataset.interface'
import { ScrollDirection } from '@/components/LazyContentLoader/scroll-direction.enum'
import { getEntry } from '@/helpers/contentLoader/get-entry.helper'

/**
 * Abstrakte Klasse, die alle Grundfunktionen für die Content-Komponenten der
 * `[[LazyContentLoader]]`-Komponente vorgibt oder/und bereitstellt.
 */
@Component({})
export class BasicContentComponent extends Vue {
  /**
   * Liste mit allen aktuell geladenen Einträgen
   */
  @Prop({ type: Array, required: true })
  public entryList!: BaseDataset[]

  /**
   * Kompaktmodus an/aus.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public compactEnabled!: boolean

  /**
   * Selektierungsmodus an/aus.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public selectEnabled!: boolean

  /**
   * Liste mit allen aktuell ausgewählten Einträgen.
   */
  @Prop({ type: Array, required: false, default: (): BaseDataset[] => [] })
  public selectEntries!: BaseDataset[]

  /**
   * Text der immer als letzter Eintrag ausgegeben werden soll.
   */
  @Prop({ type: String, required: false, default: '' })
  public footerText!: string

  /**
   * Randabstand der am Ende der Liste eingefügt werden soll.
   */
  @Prop({ type: Number, required: false, default: 72 })
  public marginBottom!: number

  /**
   * Der letzte Wert der `scrollTop`-Position vom Scrollwrapper ([[wrapper]]).
   * Wird verwendet um die Scrollrichtung bestimmen zu können.
   */
  protected lastScrollTopPos = 0

  /**
   * Index des ersten Eintrages der in der View gerendert wird.
   */
  protected firstPos = 0

  /**
   * Index des letzten Eintrages der in der View gerendert wird.
   */
  protected lastPos = 0

  /**
   * Element, das als Scroll-Container verwendet wird.
   */
  protected wrapper: Element | null = null

  /**
   * Gibt die Anzahl der Zeilen zurück die zusätzlich Gerendert werden müssen.
   * Diese werden nicht für die Ausgabe der Einträge verwendet.
   *
   * @returns Anzahl extra Zeilen.
   */
  public get countHelperRows(): number {
    return Math.max(
      this.selectEnabled
        ? Math.ceil(Math.max(this.marginBottom, 0) / this.itemHeight)
        : 0,
      this.footerText ? 1 : 0
    )
  }

  /**
   * Gibt die Höhe eines Eintrages in der Liste zurück.
   *
   * @returns Höhe des Eintrages.
   */
  public get itemHeight(): number {
    return 48
  }

  /**
   * Gibt nur die Einträge zurück, die auch im entsprechenden View ausgegeben
   * werden können.
   *
   * @returns Die sichtbaren Einträge.
   */
  public get entries(): BaseDataset[] {
    const entries = this.entryList.slice(this.firstPos, this.lastPos)

    if (this.lastPos >= this.entryList.length) {
      this.addHelperRows(entries)
    }

    return entries
  }

  /**
   * Fügt die benötigten Hilfeinträge an das übergebene Array an.
   *
   * @param entries - Array welches um die Hilfeinträge erweitert wird.
   */
  public addHelperRows(entries: BaseDataset[]): void {
    const helperRow = this.countHelperRows

    if (helperRow > 0) {
      for (let i = helperRow; i > 0; i--) {
        entries.push({
          _isFooter: true,
          text: helperRow === i ? this.footerText : ''
        })
      }
    }
  }

  /**
   * Prüft ob bereits ein Eintrag mit der angegebenen ID vom Benutzer ausgewählt
   * worden ist.
   *
   * @param id - ID des Eintrages
   * @returns Höhe des Eintrages.
   */
  public isSelected(id: number): boolean {
    return (
      this.selectEntries.length !== 0 &&
      this.selectEntries.findIndex(
        (entry: BaseDataset): boolean => !!entry && entry.id === id
      ) !== -1
    )
  }

  /**
   * Setzt bzw. entfernt den angegeben Eintrag aus der Liste mit ausgewählten
   * Einträgen. Die Angabe erfolgt über die ID des Eintrages. Ebenfalls wird
   * geprüft ob der Eintrag auch im Property [[this.entryList]] enthalten ist.
   *
   * @param id - ID des Eintrages
   */
  public toggleSelected(id: number): void {
    const idx = this.entryList.findIndex(
      (entry: BaseDataset): boolean => !!entry && entry.id === id
    )

    if (idx === -1) {
      throw new Error(`toggleSelected(): entry #${id} not found in 'entryList'`)
    }

    const selectedIdx = this.selectEntries.findIndex(
      (entry: BaseDataset): boolean => !!entry && entry.id === id
    )

    if (selectedIdx === -1) {
      this.selectEntries.push(this.entryList[idx])
    } else {
      this.selectEntries.splice(selectedIdx, 1)
    }
  }

  /**
   * Gibt den Tabellenwert entsprechend der eingestellten Formatierungsregeln
   * zurück.
   */
  public getEntry = getEntry

  /**
   * Setzt die `scrollTop`-Position des Scroll-Container wieder auf 0 zurück.
   * Dadurch wird die Auflistung wieder an den Anfang gesetzt.
   */
  public resetScrollTop(): void {
    if (this.wrapper) {
      this.lastScrollTopPos = this.wrapper.scrollTop = 0
    } else {
      this.lastScrollTopPos = 0
    }
  }

  /**
   * Setzt die Scrollposition von [[this.wrapper]] auf die übergebene Position
   * (dadurch wird die View gescrollt).
   *
   * @param position - Position des Eintrages in [[this.entryList]], zu der
   * gescrollt werden soll.
   */
  public scrollToElement(position: number): void {
    const length = this.entryList.length
    const height = length > position ? this.itemHeight * position : 0

    if (this.wrapper) {
      this.lastScrollTopPos = this.wrapper.scrollTop = height
    } else {
      this.lastScrollTopPos = height
    }
  }

  /**
   * Triggert das Event `list-row-click`, welches den Eintrag und dessen Index
   * weitergibt.
   *
   * @param index - Index, der angeklickten Zeile; bezieht sich auf den
   * gerenderten Part
   * @returns Eintrag, der angeklickten Zeile.
   */
  @Emit('list-row-click')
  public eventListRowClick(index: number): BaseDataset {
    const entry = this.entries[index]
    return entry ? { ...entry } : entry
  }

  /**
   * Triggert das Event `scroll-display-items-range`, welches die aktuell
   * angezeigten Eintrqge, an der ersten und letzten Position, als Index-Wert
   * weiterreicht. Zusätzlich auch die Richtung, die der Benutzer gescrollt hat.
   *
   * @param firstPos - Index des ersten Datensatzes der angezeigt wird.
   * @param lastPos - Index des letzten Datensatzes der angezeigt wird.
   * @returns Richtung, in die der Benutzer scrollt.
   */
  @Emit('scroll-display-items-range')
  public eventScrollDisplayItemsRange(
    firstPos: number,
    lastPos: number
  ): ScrollDirection {
    const wrapper = this.wrapper
    let scrollTop = this.lastScrollTopPos

    if (wrapper) {
      scrollTop = wrapper.scrollTop
    }

    const scrollDir =
      this.lastScrollTopPos > scrollTop
        ? ScrollDirection.Up
        : ScrollDirection.Down

    this.lastScrollTopPos = scrollTop
    this.firstPos = firstPos
    this.lastPos = lastPos

    return scrollDir
  }
}
