
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

/**
 * Diese Komponente bindet Floating-Buttons ein. Enthalten sind standardmäßig
 * Buttons für das Erstellen, Bearbeiten und Löschen von Einträgen.
 *
 * Es werden zwei Modi unterstützt:
 * 1. (Default) Es wird ein Button zum Öffnen der eigentlichen Buttons angezeigt.
 * Für die Erweiterung dieser Liste um eigene Buttons können die beiden Slots
 * `buttonUp` und `buttonDown` verwendet werden.
 * 2. (Durch Property [[singleButton]]) Der einzelne Button wird wie ein Button
 * zum Erstellen von Einträgen behandelt. Durch den Slot `singleButton` kann
 * dieser komplett ausgetauscht werden.
 */
@Component({})
export default class FloatingActionButton extends Vue {
  /**
   * Es wird nur ein Button angezeigt. Dieser wird standardmäßig wie ein Button
   * zum Erstellen von Einträgen behandelt. Durch den Slot `singleButton` kann
   * dieser komplett ersetzt werden.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public singleButton!: boolean

  /**
   * Button für das Erstellen von Einträgen anzeigen.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public showButtonCreate!: boolean

  /**
   * Button für das Bearbeiten von Einträgen anzeigen.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public showButtonEdit!: boolean

  /**
   * Button für das Entfernen von Einträgen anzeigen.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public showButtonRemove!: boolean

  /**
   * Die Richtung in welche die Buttons ausgegeben bzw. angezeigt werden.
   */
  @Prop({
    default: 'top',
    required: false,
    validator: (x): boolean => !x || x === 'top' || x === 'left'
  })
  public visibleDirection!: 'top' | 'left'

  /**
   * Ob die Buttons ausgegeben bzw. angezeigt werden.
   */
  public visibleButtons = false

  /**
   * Trigert das Event `click-create`.
   */
  @Emit('click-create')
  public onClickCreate(): void {}

  /**
   * Trigert das Event `click-edit`.
   */
  @Emit('click-edit')
  public onClickEdit(): void {}

  /**
   * Trigert das Event `click-remove`.
   */
  @Emit('click-remove')
  public onClickRemove(): void {}
}
