
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { BasicContentComponent } from '@/mixins/basicContentComponent.mixin'
import { ListColumn } from '@/interfaces/ContentLoader/list-column.interface'
import { BaseDataset } from '@/interfaces/ContentLoader/base-dataset.interface'

/**
 * Hilfsinterface für die Vuetify-Komponente 'vVirtualScroll'. Ermöglicht den
 * Zugriff auf die benötigten Properties der Komponente. Aktuell liefert Vuetify
 * keine eigenen Interfaces für seine Komponenten mit.
 */
interface VuetifyVirtualScroll {
  /**
   * Der Index des Eintrages, aus dem Array, welches die Vuetify-Komponente
   * 'vVirtualScroll' im Interface an der ersten Position in der View ausgibt.
   * Ist nicht der erste Eintrag der gerendert wird, sondern der Erste, der in
   * der View des Containers existiert.
   */
  first: number

  /**
   * Der Index des Eintrages, aus dem Array, welches die Vuetify-Komponente
   * 'vVirtualScroll' im Interface in der letzten Position in der View ausgibt.
   * Ist nicht der letzte Eintrag der gerendert wird, sondern der Letzte, der in
   * der View des Containers existiert.
   */
  last: number
}

/**
 * Rendert eine Liste mit dem übergebenen Inhalt
 */
@Component({})
export default class ContentList extends Mixins(BasicContentComponent) {
  /**
   * Spalten der Liste
   */
  @Prop({ type: Object, required: true })
  public listColumns!: ListColumn

  /**
   * Gibt die beiden Properties `first` und `last` von der Vuetify-Komponente
   * `vVirtualScroll` zurück. Diese geben die Start- und Endposition der sicht-
   * baren Einträge zurück.
   *
   * @returns `first` und `last` von der Komponente `vVirtualScroll`
   */
  public get vVirtualScroll(): VuetifyVirtualScroll | undefined {
    const vVirtualScroll = this.$refs.vVirtualScroll

    return vVirtualScroll && 'last' in vVirtualScroll
      ? (vVirtualScroll as unknown as VuetifyVirtualScroll)
      : undefined
  }

  /**
   * Gibt die Höhe eines Eintrages in der Liste zurück.
   *
   * @returns Höhe des Eintrages.
   */
  public get itemHeight(): number {
    return this.compactEnabled ? 48 : 64
  }

  /**
   * Gibt die Größe des Avatars zurück.
   *
   * @returns Größe des Avatars.
   */
  public get avatarSize(): number {
    return this.compactEnabled ? 32 : 40
  }

  /**
   * Gibt alle Einträge mit den Hilfeinträge zurück. Wird nur in der View für
   * die Ausgabe verwendet.
   *
   * @returns Alle sichtbaren Einträge
   */
  public get entries(): BaseDataset[] {
    const entries = [...this.entryList]
    this.addHelperRows(entries)
    return entries
  }

  /**
   * Vue-Hook `mounted`.
   */
  public mounted(): void {
    this.wrapper = this.$el
    this.resetScrollTop()
  }

  /**
   * Prüft, ob der Klick des Benutzers auf eine Zeile gültig ist. Ist dies der
   * Fall, wird das Event `list-row-click` ausgeführt.
   *
   * @param index - Index von der angeklickten Zeile.
   * @param event - Original-Event.
   */
  public onEntryClick(index: number, event: Event): void {
    const entry = this.entries[index]
    const target =
      event && event.target && 'className' in event.target
        ? (event.target as Element)
        : null

    if (entry && (!target || target.className.indexOf('v-input--') === -1)) {
      this.eventListRowClick(index)
    }
  }

  /**
   * Gibt die Positionen der Einträge im sichtbaren Bereich und die
   * Scrollrichtung über das Event `scroll-display-items-range` weiter.
   */
  public onScroll(): void {
    const virtualScroll = this.vVirtualScroll

    if (virtualScroll) {
      const firstPos = Math.max(0, virtualScroll.first)
      const lastPos = Math.min(virtualScroll.last, this.entryList.length)

      this.eventScrollDisplayItemsRange(firstPos, lastPos)
    }
  }
}
