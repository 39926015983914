/**
 * Scrollrichtung.
 */
export enum ScrollDirection {
  /**
   * Runter scrollen.
   */
  Down,

  /**
   * Hoch scrollen.
   */
  Up
}
