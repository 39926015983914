
import { Component, Emit, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'
import { Filter } from '@/interfaces/Filter.interface'
import { parsePresettingFromQuery } from '@/helpers/search'
import { BaseDataset } from '@/interfaces/ContentLoader/base-dataset.interface'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import { ListColumn } from '@/interfaces/ContentLoader/list-column.interface'
import { Queue } from '@/interfaces/ContentLoader/queue.interface'
import { OrderDirection } from './order-direction.enum'
import { ScrollDirection } from './scroll-direction.enum'
import { OrderOption } from '@/interfaces/ContentLoader/order-option.interface'
import { ListOrderOption } from '@/interfaces/ContentLoader/list-order-option.interface'
import { Request } from '@/interfaces/ContentLoader/request.interface'
import {
  Paginated,
  typeSourceCallback,
  serviceSourceCallback
} from '../../helpers/contentLoader/source.helper'
import ContentList from '@/components/ContentList/ContentList.vue'
import ContentTable from '../ContentTable/ContentTable.vue'
import FloatingActionButton from '../FloatingActionButton/FloatingActionButton.vue'
import { BasicContentComponent } from '@/mixins/basicContentComponent.mixin'

const UIStore = namespace('ui')

/**
 * Maximale Anzahl der Einträge die bei einem Request zusammen geladen werden
 * dürfen. Verhindert, dass durch [[LazyContentLoader.limit]] und
 * [[LazyContentLoader.bench]], zuviele Einträge pro Request geladen werden.
 */
const MAX_LIMIT = 100

/**
 * Minimale Anzahl der Einträge die bei einem Request zusammen geladen werden
 * dürfen. Verhindert, dass durch [[LazyContentLoader.limit]] und
 * [[LazyContentLoader.bench]], weniger Einträge pro Request geladen werden.
 */
const MIN_LIMIT = 1

/**
 * Universelle, konfigurierbare Listen-/Tabellenansicht.
 */
@Component({
  components: { ContentList, ContentTable, FloatingActionButton }
})
export default class LazyContentLoader extends Vue {
  /**
   * Spaltenkonfiguration.
   */
  @Prop({ type: Array, required: true })
  public columns!: Column[]

  /**
   * Die Datenquelle von dem die Datensätze abgefragt werden. Dies kann ein
   * Service-Pfad sein oder ein Callback vom Typ [[typeSourceCallback]].
   */
  @Prop({ type: [String, Function], required: true })
  public source!: string | typeSourceCallback

  /**
   * Anzahl der Einträge die bei einem Request geladen werden. Der angegebene
   * Wert muss mindestens 1 sein. Intern wird [[this.requestLimit]] verwendet!
   * Wird auch beim ersten Request verwendet,danach wird der Wert  automatisch
   * berechnet.
   */
  @Prop({
    type: Number,
    required: false,
    default: 25,
    validator: (v: unknown): boolean =>
      typeof v === 'number' && Number.isInteger(v) && v > 0
  })
  public limit!: number

  /**
   * Anzahl der Einträge die bei einem Request geladen werden.
   */
  public requestLimit!: number

  /**
   * Die Anzahl der Einträge die zusätzlich mit geladen werden und außerhalb des
   * sichtbaren Bereiches liegen. Die Zahl wird immer komplett für eine Richtung
   * verwendet, also `x` nach oben und `x` nach unten.
   */
  @Prop({
    type: Number,
    required: false,
    default: 15,
    validator: (v: unknown): boolean =>
      typeof v === 'number' && Number.isInteger(v) && v > 0
  })
  public bench!: number

  /**
   * Standard-Icon für alle Icon-Spalten, wenn der Wert `true` ist.
   */
  @Prop({ type: String, required: false, default: 'mdi-check' })
  public iconTrue!: string

  /**
   * Standard-Icon für alle Icon-Spalten, wenn der Wert `false` ist.
   */
  @Prop({ type: String, required: false, default: 'mdi-close' })
  public iconFalse!: string

  /**
   * Property von der Spalte die Initial für die Sortierung verwendet werden
   * soll. Nicht reaktiv.
   */
  @Prop({ type: String, required: true })
  public initialOrderBy = ''

  /**
   * Der Ansichtsmodus der Initial verwendet werden soll, nicht reaktiv. Es sind
   * die Werte `'auto'` (Standard), `'list'` und `'table' erlaubt.
   */
  @Prop({ type: String, required: false, default: 'auto' })
  public initialViewMode!: 'list' | 'table' | 'auto'

  /**
   * Selektierungsmodus an/aus.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public selectEnabled!: boolean

  /**
   * Bearbeitungsbutton auch anzeigen, wenn mehreren Einträgen ausgewählt sind.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public allowMultipleEdit!: boolean

  /**
   * Context-Menü: Button für das Erstellen von Einträgen anzeigen.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public showButtonCreate!: boolean

  /**
   * Context-Menü: Button für das Bearbeiten von Einträgen anzeigen.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public showButtonEdit!: boolean

  /**
   * Context-Menü: Button für das Entfernen von Einträgen anzeigen.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public showButtonRemove!: boolean

  /**
   * Alle Einträge die vom Benutzer ausgewählt wurden.
   */
  public selectEntries: BaseDataset[] = []

  /**
   * Kompaktmodus an/aus. Sollte initial passend gesetzt werden!
   */
  @Prop({ type: Boolean, required: false, default: false })
  public compactEnabled!: boolean

  /**
   * Gibt alle Spalten der Tabelle in den Store weiter, um das Filterset
   * aus der Konfiguration bauen zu können
   */
  @UIStore.Action('setColumns')
  public setColumns!: (columns: Column[]) => Promise<void>

  /**
   * Setzt die Filterung in den Store, hier genutzt, um bei einer Änderung der
   * Liste oder bei einem  Seitenwechsel die Filter zurückzusetzen
   */
  @UIStore.Action('setFilters')
  public setFilters!: (filters: Filter[]) => Promise<void>

  /**
   * Setzt die Spalten die von der globalen Suche berücksichtigt werden sollen.
   */
  @UIStore.Action('setGlobalSearchColumns')
  public setGlobalSearchColumns!: (columns: string[]) => Promise<void>

  /**
   * Suchstring aus dem globalen Suchfeld.
   */
  @UIStore.State('globalSearch')
  public globalSearch!: string

  /**
   * Holt das aktuell gesetzte Filterset aus dem Store
   */
  @Getter('filters', { namespace: 'ui' })
  public filters!: Filter[]

  /**
   * Holt das aktuell gesetzte Filterset aus dem Store
   */
  @UIStore.State('globalSearchColumns')
  public globalSearchColumns!: string[]

  /**
   * Aufbereitete Spaltenkonfiguration für die Listenansicht
   */
  public listColumns: ListColumn = {}

  /**
   * Aufbereitete Spaltenkonfiguration für die Tabellenansicht
   */
  public tableColumns: Column[] = []

  /**
   * Der interne erwendete Callback für das Nachladen der Datensätze von der
   * Datenquelle. Dieser wird über  [[this.source]] gesetzt.
   */
  protected sourceCallback?: typeSourceCallback

  /**
   * Queue, enthält die Funktionen um die nächte Abfrage an der Datenquelle
   * abzusenden oder diese abzubrechen. Wird gesetzt, wenn eine neue Abfrage an
   * die Datenquelle gestartet wird, obwohl die letzte noch nicht Abgeschlossen
   * wurde.
   */
  protected queue?: Queue

  /**
   * Ergebnis der letzten Abfrage der Datensätze durch [[this.sourceCallback]].
   */
  public entryList: (BaseDataset | null)[] = []

  /**
   * ID für den aktuell verwendeten [[this.sourceCallback]]. Wird beim Ändern
   * des Source verändert, also durch die Funktion [[this.onSourceChange]].
   */
  protected sourceID = -1

  /**
   * Die gesendeten Paramatern der letzten Abfrage an die Datenquelle. Wird zum
   * prüfen der Abfrage verwendet, damit nur die letzte Abfrage übernommen wird.
   */
  protected lastRequestParam?: Request

  /**
   * Anzahl der Einträge die übersprungen werden sollen bei einem Request.
   */
  public offset = 0

  /**
   * Konstante für die aufsteigende Sortierreihenfolge.
   */
  public ORDER_DIR_ASCENDING = OrderDirection.Ascending

  /**
   * Property der Spalte die Sortiert werden soll.
   */
  public orderBy = this.initialOrderBy

  /**
   * Sortierreihenfolge.
   */
  public orderDirection: OrderDirection = OrderDirection.Ascending

  /**
   * Verfügbare Spalten für die Sortierung in der Listenansicht.
   */
  public orderOptions: ListOrderOption = {}

  /**
   * Ansichtsmodus (Liste oder Tabelle).
   */
  public viewMode: 'list' | 'table' = 'list'

  /**
   * Gibt an, ob gerade Einträge nachgeladen werden.
   */
  public isLoading = false

  /**
   * Gibt an, ob momentan die Komponente für das Anzeigen der Einträge
   * ausgetauscht wird.
   */
  public isChangingViewMode = false

  /**
   * Gibt an, ob gerade das `scoll`-Event verarbeitet wird. Dies beinhaltet
   * nicht das nachgeladen von Einträgen!
   */
  protected isScrolling = false

  /**
   * Ob der Ansichtsmodus je nach Browserfenster größe Automatisch gewechselt
   * werden soll.
   */
  public viewModeAuto = true

  /**
   * Der Index des ersten Eintrags in [[this.entryList]], der von der Inhalts-
   * komponente in der View ausgegeben wird.
   */
  public firstEntryIndex = 0

  /**
   * Der Index des letzten Eintrags in [[this.entryList]], der von der Inhalts-
   * komponente in der View ausgegeben wird.
   */
  public lastEntryIndex?: number

  /**
   * ID des letzten Timeout, der durch die globale Suche ausgelöst worden ist.
   * Wird verwendet um die abgesendete Request zu verringern. Da nicht für jeden
   * getippten Buchstaben ein Request versendet werden muss.
   */
  private timeoutGlobalSearch: number | null = null

  /**
   * Text welcher am Ende der Auflistung ausgegeben werden soll.
   *
   * @returns Footertext
   */
  public get footerText(): string {
    const count = this.entryList.length
    return this.isLoading &&
      count <= 10 &&
      this.entryList.every((x): boolean => !x)
      ? ''
      : this.$tc('system.contentList.footer', count, [count]).toString()
  }

  /**
   * Gibt alle Möglichkeiten zurück, wonach die Liste sortiert werden kann.
   *
   * @returns - Liste mit allen Sortieroptionen.
   */
  public get sortOptions(): OrderOption[] {
    return Object.values(this.orderOptions)
  }

  /**
   * Gibt die aktuell verwendete, also angezeigte, Inhaltskomponente zurück.
   *
   * @returns Die Inhaltskomponente.
   */
  public get contentComponent(): BasicContentComponent | undefined {
    return this.viewMode === 'table'
      ? (this.$refs.ContentTable as BasicContentComponent | undefined)
      : (this.$refs.ContentList as BasicContentComponent | undefined)
  }

  /**
   * Single-Button vom Context-Menü anzeigen.
   *
   * @returns Ob der Button angezeigt werden soll.
   */
  public get enabledContextButtonSingle(): boolean {
    return this.selectEnabled && this.selectEntries.length === 0
  }

  /**
   * Ob der Button zum Erstellen von Einträgen im Content-Menü angezeigt wird.
   *
   * @returns Ob der Button angezeigt werden soll.
   */
  public get enabledContextButtonCreate(): boolean {
    return this.selectEnabled && this.showButtonCreate
  }

  /**
   * Ob der Button zum Bearbeiten von Einträgen im Content-Menü angezeigt wird.
   *
   * @returns Ob der Button angezeigt werden soll.
   */
  public get enabledContextButtonEdit(): boolean {
    return (
      this.selectEnabled &&
      this.showButtonEdit &&
      (this.allowMultipleEdit || this.selectEntries.length === 1)
    )
  }

  /**
   * Ob der Button zum Entfernen von Einträgen im Content-Menü angezeigt wird.
   *
   * @returns Ob der Button angezeigt werden soll.
   * @event
   */
  public get enabledContextButtonRemove(): boolean {
    return this.selectEnabled && this.showButtonRemove
  }

  /**
   * Triggert das Event `click-button`. Ist [[`this.selectEnabled`]] auf `true`,
   * werden alle ausgewählte Einträge mitgegeben.
   *
   * @param name - Name des geklickten Floating-Buttons
   * Standardwerte: `create`, `edit`, `remove`
   * @param object - Instance von dazugehörender [[LazyContentLoader]]-Komponente
   * @returns Auflistung aller ausgewählten Einträge.
   * @event
   */
  @Emit('click-button')
  public triggerButtonClick(
    name: string,
    object: LazyContentLoader
  ): BaseDataset[] {
    name = name.trim()

    if (name === '') {
      throw new Error('onEntriesRemove(): argument `name` is empty')
    }

    if (typeof object !== 'object' || Object.keys(object).length === 0) {
      throw new Error('onEntriesRemove(): argument `object` is invalid')
    }

    const selected: BaseDataset[] = []

    if (this.selectEnabled) {
      for (const entry of this.selectEntries) {
        if (entry) {
          selected.push({ ...entry })
        }
      }
    }

    return selected
  }

  /**
   * Reicht nur das Event `list-row-click` der Inhaltskomponenten weiter.
   *
   * @param entry - Eintrag, der angeklickten Zeile.
   * @param index - Index, der angeklickten Zeile.
   */
  @Emit('list-row-click')
  public onListRowClick(entry: BaseDataset, index: number): void {
    if (typeof entry !== 'object') {
      throw new Error(
        "onListRowClick() argument `entry` isn't from type object"
      )
    }

    if (!Number.isInteger(index) || index < 0) {
      throw new Error(
        `onListRowClick() argument \`index\` is invalid - value: ${index}`
      )
    }
  }

  /**
   * Triggert das Event `select-count`.
   *
   * @returns Anzahl der ausgewählten Einträgen
   * @event
   */
  @Emit('select-count')
  public eventSelectCount(): number {
    return this.selectEntries.length
  }

  /**
   * Triggert das Event `select`. Bei der Rückgabe wird nur eine Kopie des Array
   * zurückgegeben, aber nicht von den Objekten - also: KEIN DEEP-COPY!
   *
   * @returns Kopie des Array mit den ausgewählten Einträge
   * @event
   */
  @Emit('select')
  public eventSelect(): BaseDataset[] {
    return [...this.selectEntries]
  }

  /**
   * Vue-Hook `created`. Diese Methode wird von der Komponente ausgefürt, wenn
   * diese zum erstenmal Erzeugt worden ist. Es werden alle Basic-Funktionen
   * der Komponente ausgeführt und die ersten Datensätze geladen.
   */
  public created(): void {
    this.requestLimit = this.limit
    if (this.initialViewMode === 'list' || this.initialViewMode === 'table') {
      this.viewMode = this.initialViewMode
      this.viewModeAuto = false
    }
    this.onColumnsChange(this.columns)
    this.onSourceChange(this.source)
  }

  /**
   * Vue-Hook `beforeDestroy`. Wird ausgefürt, wenn die Komponente durch Vue
   * zerstört wird. Innerhalb dieser Methode werden alle im UI-Store
   * gespeicherten Informationen gelöscht.
   */
  public beforeDestroy(): void {
    this.setColumns([])
    this.resetFilters()
    this.clearQueue()
  }

  /**
   * Setzt die aktivierten Filter-Optionen, die im UI-Store gespeichert sind,
   * zurück.
   */
  protected resetFilters(): void {
    this.setFilters([])
    this.setGlobalSearchColumns([])
  }

  /**
   * Beendet bzw. bricht die wartende Datenband-Abfrage ab, die in der Queue
   * zwischengespeichert worden ist.
   */
  public clearQueue(): void {
    if (this.queue) {
      this.queue.reject()
      this.queue = undefined
    }
  }

  /**
   * Leert die Liste mit den ausgewählten Einträgen.
   */
  public clearSelect(): void {
    this.selectEntries.splice(0, this.selectEntries.length)
  }

  /**
   * Achtet darauf, ob sich die Spalten aus [[this.columns]] neu gesetzt werden.
   * Passiert dieses, werden alle Informationen die sich aus den Spalten ergeben
   * haben erneuert.
   */
  @Watch('compactEnabled')
  public toggleCompactEnabledt(): void {
    this.loadSource()
  }

  /**
   * Triggert passende Events, wenn die ausgewählten Einträge sich ändern.
   */
  @Watch('selectEntries')
  public onSelectEntries(): void {
    this.eventSelectCount()
    this.eventSelect()
  }

  /**
   * Achtet darauf, ob sich die Spalten aus [[this.columns]] neu gesetzt werden.
   * Passiert dieses, werden alle Informationen die sich aus den Spalten ergeben
   * haben erneuert.
   */
  @Watch('globalSearch')
  public onGlobalSearchChange(): void {
    if (this.timeoutGlobalSearch) {
      window.clearTimeout(this.timeoutGlobalSearch)
      this.timeoutGlobalSearch = null
    }

    this.timeoutGlobalSearch = window.setTimeout((): void => {
      this.timeoutGlobalSearch = null
      this.loadSource()
    }, 500)
  }

  /**
   * Wenn die Filter gewechselt werden, wird die Inhaltskomponente zurück an den
   * Anfang gesetzt und die passende Einträge geladen.
   */
  @Watch('filters')
  public resetContentComponent(): void {
    this.refreshSource()
  }

  /**
   * Wenn die Spalten, die durchsucht werden sollen, gewechselt werden und ein
   * Suchbegriff vorhanden sind, wird die Inhaltskomponente zurück an den Anfang
   * gesetzt und die passende Einträge geladen.
   */
  @Watch('globalSearchColumns')
  public onGlobalSearchColumns(): void {
    if (this.globalSearch) {
      this.refreshSource()
    }
  }

  /**
   * Gibt die aktuelle Position in der Inhaltskomponente an die neue Komponente
   * weiter. Wenn die Ansicht geändert wird.
   */
  @Watch('viewMode')
  public switchContentComponent(): void {
    this.isChangingViewMode = true
    this.$nextTick((): void => {
      const content = this.contentComponent
      if (content) {
        content.scrollToElement(this.firstEntryIndex)
        this.isChangingViewMode = false
      }
    })
  }

  /**
   * Berechnet im für das `lazyloading` ([[this.loadingMode]]) den Offset für
   * die Datensätze-Abfrage anhand der aktuell angezeigten Datensätze. Sollte
   * das Offset die aktuellen vorhandenen Datensätze über- oder unterschreiten,
   * werden die benötigten neuen Datensätze geladen.
   *
   * @param scrollDir - Richtung, in die der Benutzer scrollt.
   * @param first - Index des ersten Datensatzes der angezeigt wird.
   * @param last - Index des letzten Datensatzes der angezeigt wird.
   */
  public onScrollDisplayItemsRange(
    scrollDir: ScrollDirection,
    first: number,
    last: number
  ): void {
    if (this.isChangingViewMode || this.isScrolling) {
      return
    }

    this.isScrolling = true
    const lastEntryIndex = this.lastEntryIndex
    const maxEntries = this.entryList.length
    const doubleBench = this.bench * 2
    const halfBench = Math.floor(this.bench / 2)
    const limit = last - first + doubleBench
    const previousLast = this.offset + this.requestLimit
    let offset: number | undefined

    if (scrollDir === ScrollDirection.Down) {
      if (!lastEntryIndex || last >= maxEntries - this.bench) {
        first = maxEntries - limit
        if (previousLast <= last) {
          offset = first
          last = maxEntries
        }
      } else if (lastEntryIndex < first) {
        offset = first - this.bench
      } else if (previousLast - this.bench < last) {
        offset = lastEntryIndex
      } else if (this.requestLimit < limit) {
        offset = lastEntryIndex
      }
    } else if (scrollDir === ScrollDirection.Up) {
      if (this.offset <= 0 || first <= 0) {
        if (this.offset !== 0) {
          offset = first
          last = limit
        }
      } else if (previousLast > last + this.bench) {
        offset = this.firstEntryIndex - limit + halfBench
      } else if (first < this.firstEntryIndex && first < this.offset) {
        offset = first - this.bench - halfBench
      }
    }

    this.firstEntryIndex = first > 0 ? first : 0
    this.lastEntryIndex = last < maxEntries ? last : maxEntries

    if (typeof offset === 'number') {
      let newRequestLimit = limit

      if (scrollDir === ScrollDirection.Down) {
        if (this.entryList.slice(this.offset, offset).indexOf(null) !== -1) {
          newRequestLimit = limit + (first - offset) + 1
          offset = first - 1
        }
      } else if (first < offset) {
        offset = last + halfBench - limit
      } else if (offset + limit < last) {
        offset = last + halfBench - limit
      }

      if (this.requestLimit !== newRequestLimit) {
        this.requestLimit = newRequestLimit
      }

      this.offset = offset > 0 ? offset : 0
      this.isScrolling = false
      this.loadSource()
    } else {
      this.isScrolling = false
    }
  }

  /**
   * Achtet darauf, ob die Datenquelle der Komponente geändert wird. Bei
   * Änderungen von [[this.source]] wird die passende Funktion für
   * [[this.sourceCallback]] gesetzt.
   *
   * @param value - Der neue Wert vom Property [[this.source]]
   */
  @Watch('source')
  public onSourceChange(value?: string | typeSourceCallback): void {
    if (value) {
      if (this.orderBy !== this.initialOrderBy) {
        this.orderBy = this.initialOrderBy
      }

      // `sourceID` erhöhen, wenn `Number.MAX_SAFE_INTEGER` erreicht wurde, auf
      // `Number.MIN_SAFE_INTEGER` wechseln.
      if (this.sourceID === Number.MAX_SAFE_INTEGER) {
        this.sourceID = Number.MIN_SAFE_INTEGER
      } else {
        ++this.sourceID
      }

      if (typeof value === 'string') {
        this.sourceCallback = serviceSourceCallback
      } else if (typeof value === 'function') {
        this.sourceCallback = value
      } else {
        this.sourceCallback = undefined
      }

      this.entryList = new Array(7).fill(null)
      this.loadSource()
    }
  }

  /**
   * Achtet darauf, ob sich die Route ändert, um das Filterset zurückzusetzen
   */
  @Watch('$route', { immediate: true, deep: true })
  public onSubSiteChange(): void {
    this.resetFilters()
  }

  /**
   * Achtet darauf, ob sich die Spalten aus [[this.columns]] neu gesetzt werden.
   * Passiert dieses, werden alle Informationen die sich aus den Spalten ergeben
   * haben erneuert.
   *
   * @param value - Die neue Spalten
   */
  @Watch('columns')
  public onColumnsChange(value: Column[]): void {
    const columns = value
    const filterColumns: Column[] = []
    const listColumns: ListColumn = {}
    const tableColumns: Column[] = []
    const orderOptions: ListOrderOption = {}

    for (const column of columns) {
      const columnDescription = column.description || column.label

      if (!column.listOnly) {
        tableColumns.push(column)
      }

      if (column.listPosition) {
        listColumns[column.listPosition] = column
      }

      if (columnDescription) {
        if (column.searchable) {
          filterColumns.push(column)
        }

        if (column.sortable !== false) {
          orderOptions[column.property] = {
            text: columnDescription,
            value: column.property
          }
        }
      }
    }

    this.listColumns = listColumns
    this.tableColumns = tableColumns
    this.orderOptions = orderOptions
    this.setColumns(filterColumns)
    if (this.$route && this.$route.query) {
      const query = parsePresettingFromQuery(this.$route.query, filterColumns)
      this.setFilters(query.filters)
      this.setGlobalSearchColumns(query.searchColumns)
    }
  }

  /**
   * Lädt die Einträge komplett neu und setzt die Inhaltskomponente zurück,
   * sodass diese die Inhalt von der ersten Seite anzeigt werden.
   */
  public refreshSource(): void {
    const content = this.contentComponent

    if (content) {
      content.resetScrollTop()
    }

    this.offset = 0
    this.loadSource()
  }

  /**
   * Gibt das Click-Event von einem Floating-Button weiter. Dies passiert aber
   * nur, wenn die Komponente im Bearbeitungsmodus ist, also wenn die Property
   * [[`this.selectEnabled`]] `true` ist.
   *
   * @param name - Name des Events, das weitergeben wird.
   */
  public onButtonClick(name: string): void {
    if (this.selectEnabled && typeof name === 'string') {
      this.triggerButtonClick(name.trim(), this as LazyContentLoader)
    }
  }

  /**
   * Erzeugt die Paramatern für die nächste Abfrage von den Datensätzen und
   * speichert diese in [[this.lastRequestParam]].
   *
   * @returns - Die neu erzeugten Abfrage-Paramatern
   */
  public buildRequestParams(): Request {
    let limit = this.requestLimit

    if (limit > MAX_LIMIT) {
      limit = MAX_LIMIT
    } else if (limit < MIN_LIMIT) {
      limit = MIN_LIMIT
    }

    this.lastRequestParam = {
      $limit: limit,
      $offset: this.offset,
      $orderBy: this.orderBy,
      $orderDirection: this.orderDirection,
      $search: this.globalSearch,
      $searchColumns: this.globalSearchColumns,
      $filters: this.filters
    }

    return this.lastRequestParam
  }

  /**
   * Versendet ein Request zum laden der Datensätze an die angebene Datenquelle
   * gesendet. Enthalten in dieser Abfrage sind alle Filter- und
   * Sortierungsinformationen.
   *
   * @returns - Gibt das Ergebnis als [[Paginated]] Typ zurück. Tritt ein Fehler
   * auf, wird `null` zurückgegeben.
   */
  public async loadSource(): Promise<Paginated | null> {
    const sourceID = this.sourceID

    if (this.isLoading) {
      // neue Abfrage an die Datenquelle pausieren, bis die aktuell durchgefürte
      // Datenbankabfrage abgeschlossen worden ist. Eine vorhergegangene Abfrage
      // die bereits pausiert worden ist, wird abgebrochen.
      const queue = new Promise<Queue>((resolve, reject): void => {
        this.clearQueue()

        this.queue = {
          resolve,
          reject,
          offset: this.offset,
          limit: this.requestLimit
        }
      })

      const success = await queue.then(
        (queue: Queue): boolean => {
          this.offset = queue.offset
          this.requestLimit = queue.limit
          return true
        },
        (): false => false
      )

      if (!success) {
        return null
      }
    }

    let result: Paginated | null

    try {
      this.isLoading = true

      if (!this.sourceCallback) {
        throw new Error('source information missing')
      }

      result = await this.sourceCallback(this.buildRequestParams(), this)

      if (sourceID !== this.sourceID) {
        throw new Error('request from an outdated source')
      }

      const requestParams = this.lastRequestParam
      if (!requestParams || !result) {
        throw new Error(`missing request data`)
      }

      const limit = requestParams.$limit
      const offset = requestParams.$offset

      if (
        result.limit !== limit ||
        !('skip' in result) ||
        result.skip !== offset
      ) {
        // eslint-disable-next-line no-console
        console.info('LazyContentLoader: outdated request')
      } else {
        const entryList: (BaseDataset | null)[] = new Array(result.total)
        const oldEntryList = this.entryList
        const oldFirst = this.firstEntryIndex - this.bench - limit
        const oldLast = (this.lastEntryIndex || oldFirst) + this.bench + limit
        const newFirst = offset
        const newLast = newFirst + limit

        for (let idx = 0; idx < result.total; ++idx) {
          if (idx >= newFirst && idx <= newLast) {
            entryList[idx] = result.data[idx - newFirst]
          } else if (idx >= oldFirst && idx <= oldLast) {
            entryList[idx] = oldEntryList[idx]
          } else {
            entryList[idx] = null
          }
        }

        this.entryList = entryList
      }
    } catch (ex) {
      if (!this.entryList.some((x): boolean => !!x)) {
        this.entryList = []
      }
      throw ex
    } finally {
      this.isLoading = false

      if (this.queue) {
        this.queue.resolve(this.queue)
        this.queue = undefined
      }
    }

    return result
  }

  /**
   * Wechselt zwischen Tabellen- und Listenansicht.
   */
  public toggleViewMode(): void {
    this.viewMode = this.viewMode === 'list' ? 'table' : 'list'
  }

  /**
   * Schaltet die Sortierreihenfolge zwischen [[OrderDirection.Ascending]] und
   * [[OrderDirection.Descending]] um. Ermöglicht auch das wechseln der
   * Betroffene Eigenschaft.
   *
   * @param property - Eigenschaft, nach der sortiert werden soll.
   * @param direction - Gewünschte Sortierreihenfolge, wenn nicht angegeben
   * wird einfach gewechselt
   */
  public toggleOrder(property?: string, direction?: OrderDirection): void {
    if (this.orderBy !== property && property) {
      if (this.orderOptions[property]) {
        this.orderBy = property
        this.orderDirection = direction || OrderDirection.Ascending
        this.loadSource()
      }
    } else if (direction) {
      if (this.orderDirection !== direction) {
        this.orderDirection = direction
        this.loadSource()
      }
    } else if (this.orderDirection === OrderDirection.Ascending) {
      this.orderDirection = OrderDirection.Descending
      this.loadSource()
    } else {
      this.orderDirection = OrderDirection.Ascending
      this.loadSource()
    }
  }
}
