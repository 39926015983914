
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator'
import { BaseDataset } from '@/interfaces/ContentLoader/base-dataset.interface'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import { OrderDirection } from '../LazyContentLoader/order-direction.enum'
import { BasicContentComponent } from '@/mixins/basicContentComponent.mixin'

/**
 * Rendert eine Tabelle mit dem übergebenen Inhalt
 */
@Component({})
export default class ContentTable extends Mixins(BasicContentComponent) {
  /**
   * Spalten der Tabelle
   */
  @Prop({ type: Array, required: true })
  public tableColumns!: Column[]

  /**
   * Property der Spalte die Sortiert werden soll.
   */
  @Prop({ type: String, required: true })
  public orderBy!: string

  /**
   * Sortierreihenfolge.
   */
  @Prop({ type: String, required: true })
  public orderDirection: OrderDirection = OrderDirection.Ascending

  /**
   * Konstante für die aufsteigende Sortierreihenfolge.
   */
  public ORDER_DIR_ASCENDING = OrderDirection.Ascending

  /**
   * Hilfselement, für den Scroll-Container. Erzwingt den Scrollbalken.
   */
  protected wrapperHelper?: HTMLElement

  /**
   * Anzahl der Einträge die am Ende zusätzlich mit gerendert werden sollen.
   */
  protected readonly itemBench: number = 2

  /**
   * Gibt die Höhe eines Eintrages in der Liste zurück.
   *
   * @returns Höhe des Eintrages.
   */
  public get itemHeight(): number {
    return this.compactEnabled ? 32 : 48
  }

  /**
   * Index des letzten Eintrages, der mit in den DOM gerendert wird.
   *
   * @returns Index des letzten Eintrages für das Rendern.
   */
  public get lastPosToRender(): number {
    return Math.min(this.entryList.length, this.lastPos + this.itemBench)
  }

  /**
   * Gibt nur die Einträge zurück, die auch im entsprechenden View ausgegeben
   * werden können.
   *
   * @returns Die sichtbaren Einträge.
   */
  public get entries(): BaseDataset[] {
    const entries = this.entryList.slice(this.firstPos, this.lastPosToRender)

    if (this.lastPosToRender >= this.entryList.length) {
      this.addHelperRows(entries)
    }

    return entries
  }

  /**
   * Vue-Hook `mounted`.
   *
   * Liest den Scrollwrapper aus und bindet ein Scroll-Event an diesen. Dies
   * kann leider nicht über Vue- oder Vuetify-Funktionen durchgeführt werden, da
   * das entsprechende Element nicht direkt angesprochen werden kann.
   */
  public mounted(): void {
    this.wrapper = this.$el
      .getElementsByClassName('v-data-table__wrapper')
      .item(0)

    if (this.wrapper) {
      this.resetScrollTop()
      this.refreshScrollWrapper()
      this.wrapper.addEventListener('scroll', this.onScroll, {
        passive: true
      })
    }
  }

  /**
   * Vue-Hook `destroyed`.
   *
   * Das gebundene Scroll-Event wird entfernt.
   */
  public destroyed(): void {
    if (this.wrapper) {
      this.wrapper.removeEventListener('scroll', this.onScroll)
    }
  }

  /**
   * Die Höhe vom Scroll-Container aktualisieren, wenn die Einträge sich ändern.
   */
  @Watch('entryList')
  public watchEntryList(): void {
    this.refreshScrollWrapper()
  }

  /**
   * Fixiert die Höhe des Scroll-Containers auf die benötigte Maximalhöhe.
   * Hierfür wird ein Hilfselement erstellt.
   */
  public refreshScrollWrapper(): void {
    const wrapper = this.wrapper

    if (wrapper) {
      let node = this.wrapperHelper

      if (!node) {
        this.wrapperHelper = node = document.createElement('div')
        node.className = 'content-table__scroll-height'
        wrapper.appendChild(node)
      }

      const countEntries = this.countHelperRows + this.entryList.length
      node.style.minHeight = `${countEntries * this.itemHeight}px`
      this.onScroll()
    }
  }

  /**
   * Gibt die Styledeklaration für die angegebene Spalte zurück.
   *
   * @param index - Index der Spalte.
   * @returns Entsprechende Styledeklaration.
   */
  public getColumnStyle(index: number): Partial<CSSStyleDeclaration> {
    const column = this.tableColumns[index]
    const style: Partial<CSSStyleDeclaration> = {}

    if (column) {
      if (column.minWidth) {
        style.minWidth =
          typeof column.minWidth === 'number'
            ? `${column.minWidth}px`
            : column.minWidth
      }

      if (column.width) {
        style.width =
          typeof column.width === 'number' ? `${column.width}px` : column.width
      }

      if (column.maxWidth) {
        style.maxWidth =
          typeof column.maxWidth === 'number'
            ? `${column.maxWidth}px`
            : column.maxWidth
      }
    }

    return style
  }

  /**
   * Gibt die Breite der Spalte zurück. Wenn keine Breite angegeben ist, wird
   * `0` zurückgebeen.
   *
   * @param index - Index der Spalte.
   * @returns Spaltenbreite, `0` wenn keine breite vorhanden.
   */
  public getColumnWidth(index: number): number {
    const column = this.tableColumns[index]
    let _width = 0

    if (column) {
      if (column.minWidth) {
        const minWidth = parseInt(column.minWidth.toString(), 10)

        if (!isNaN(minWidth) && minWidth > _width) {
          _width = minWidth
        }
      }

      if (column.width) {
        const width = parseInt(column.width.toString(), 10)

        if (!isNaN(width) && width > _width) {
          _width = width
        }
      }

      if (column.maxWidth) {
        const maxWidth = parseInt(column.maxWidth.toString(), 10)

        if (!isNaN(maxWidth) && (maxWidth < _width || _width <= 0)) {
          _width = maxWidth
        }
      }
    }

    return _width
  }

  /**
   * Prüft, ob der Klick des Benutzers auf eine Zeile gültig ist. Ist dies der
   * Fall, wird das Event `list-row-click` ausgeführt.
   *
   * @param index - Index von der angeklickten Zeile.
   * @param event - Original-Event.
   */
  public onEntryClick(index: number, event: Event): void {
    const entry = this.entries[index]
    const target =
      event && event.target && 'className' in event.target
        ? (event.target as Element)
        : null

    if (entry && (!target || target.className.indexOf('v-input--') === -1)) {
      this.eventListRowClick(index)
    }
  }

  /**
   * Gibt die Positionen der Einträge im sichtbaren Bereich und die
   * Scrollrichtung über das Event `scroll-display-items-range` weiter.
   */
  public onScroll(): void {
    const wrapper = this.wrapper

    if (wrapper) {
      const height = Math.ceil(wrapper.clientHeight)
      const scrollTop = Math.ceil(wrapper.scrollTop)
      const first = Math.max(0, Math.floor(scrollTop / this.itemHeight))
      const limit = Math.ceil(height / this.itemHeight)
      const last = Math.min(this.entryList.length - 1, first + limit)

      if (this.firstPos !== first || this.lastPos !== last) {
        this.eventScrollDisplayItemsRange(first, last)
      }
    }
  }

  /**
   * Triggert das Event `toggle-order`, wodurch die Sortierung auf die
   * ausgewählte Zeile geändert wird.
   *
   * @param index - Index, der angeklickten Zeile.
   * @returns Eintrag, der angeklickten Zeile.
   */
  @Emit('toggle-order')
  public onToggleOrder(index: number): string {
    return (this.tableColumns[index] || {}).property || ''
  }
}
